export const typography = {
  display: '5.6rem',
  titleLarge: '4.8rem',
  title: '3.2rem',
  titleSmall: '2.4rem',
  textLarge: '2rem',
  text: '1.6rem',
  textSmall: '1.4rem',
  textXSmall: '1.2rem',
  caption: '1rem',
} as const

export type Typography = typeof typography

import { useCallback } from 'react'

import SubdomainEnum from '~/models/enums/SubdomainEnum'
import { getBoletoFile, getBoletoSinglePaymentFile } from '~/services/Boletos'

export const useDownloadService = () => {
  const callDownloadMethod = useCallback((subdomain: string, fileName: string): Promise<string> => {
    switch (subdomain) {
      case SubdomainEnum.Boletos:
      default:
        return getBoletoFile(fileName)
    }
  }, [])

  const callDownloadSimplePaymentMethod = useCallback((id: string): Promise<string> => getBoletoSinglePaymentFile(id), [])

  return {
    callDownloadMethod, callDownloadSimplePaymentMethod,
  }
}

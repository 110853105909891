import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDownloadService } from '~/utils/useDownloadService'
import { useFiletype } from '~/utils/useFiletype'
import { useSubdomain } from '~/utils/useSubdomain'

interface ParamsInterface {
  fileName: string
}

const Download = () => {
  const [fileLoaded,
    setFileLoaded] = useState<boolean>(false)

  const [notFound,
    setNotFound] = useState<boolean>(false)

  const subdomain = useSubdomain()
  const { fileName } = useParams<ParamsInterface>()
  const { callDownloadMethod } = useDownloadService()
  const { getMimetype } = useFiletype()

  useEffect(() => {
    const extension = fileName.split('.').pop() || 'pdf'
    const mimetype = getMimetype(extension)

    callDownloadMethod(subdomain, fileName)
      .then((data) => {
        const file = new Blob([data], { type: mimetype })
        const fileURL = URL.createObjectURL(file)
        window.location.href = fileURL
      })
      .catch(() => setNotFound(true))
      .finally(() => setFileLoaded(true))
  }, [
    callDownloadMethod,
    fileName,
    getMimetype,
    subdomain,
  ])

  return (
    <div className="App">
      <header className="App-header">
        {!fileLoaded ? (
          <>
            Download em andamento. Aguarde...
          </>
        ) : (
          <>
            {notFound ? (
              <>
                Arquivo não encontrado ou indisponível no momento. Por favor, entre em contato ou tente novamente mais tarde.
              </>
            ) : (
              <>
                Arquivo baixado com sucesso.
              </>
            )}
          </>
        )}
      </header>
    </div>
  )
}

export default Download

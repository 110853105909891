import { Breakpoint, breakpoints } from './breakpoints'

export type ResponsiveStyleValue<T> = T | { [key in Breakpoint]?: T | null }

type ResponsiveStylesValueType = string | number | null | undefined

type ResponsiveStyles<ValueType extends ResponsiveStylesValueType> = {
  property: string
  values?: ValueType | { [key in Breakpoint]?: ValueType | null }
  valueParser?: (value: ValueType) => string | undefined
}

export const responsive = <ValueType extends ResponsiveStylesValueType>(
  styles: ResponsiveStyles<ValueType>[],
) => {
  if (!styles) return ''

  return styles
    .map(({ property, values, valueParser }) => {
      if (!values) return ''
      if (typeof values === 'string' || typeof values === 'number') {
        if (valueParser) return `${property}: ${valueParser(values)};`
        return `${property}: ${values};`
      }

      const breakpointKeys = Object.keys(values as object) as Breakpoint[]

      return breakpointKeys
        .map((breakpoint) => {
          const value = values[breakpoint]
          const media = createMediaQuery(breakpoint)
          const parsedValue = valueParser && value ? valueParser(value) : value

          return `${media} {
              ${property}: ${parsedValue};
            }`
        })
        .join('\n')
    })
    .join('\n')
}

const createMediaQuery = (breakpoint?: Breakpoint) => {
  if (!breakpoint) return ''
  return `@media (min-width: ${breakpoints[breakpoint]}px)`
}

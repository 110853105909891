import React from 'react'

import { Icon, IconProps } from '../Icon'
import { Button, ButtonProps } from '../Button'
import { getColor } from '../Button/styles'

export type CopyButtonProps = {
  value?: string
  copiedText?: string
  copiedColor?: ButtonProps['color']
  iconProps?: IconProps
  slotProps?: {
    icon?: IconProps
  }
} & ButtonProps

export const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>(
  (props, ref) => {
    const {
      children,
      color,
      onClick,
      value,
      iconProps,
      copiedText = 'Copiado',
      copiedColor = 'positive',
      variant,
      slotProps,
      ...rest
    } = props

    const [copied, setCopied] = React.useState(false)

    const icon = (
      <Icon
        name={!copied ? 'ContentCopy' : 'Done'}
        size={16}
        {...iconProps}
        {...slotProps?.icon}
      />
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      navigator.clipboard.writeText(
        value || (typeof children === 'string' ? children : ''),
      )
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
      if (onClick) onClick(event)
    }

    return (
      <Button
        ref={ref}
        color={!copied ? color : copiedColor}
        variant={variant}
        onClick={handleClick}
        sx={{
          '&:not(:disabled):hover': {
            color:
              variant === 'text' && copied
                ? getColor(copiedColor).base
                : undefined,
          },
        }}
        data-testid="copy-button"
        {...rest}
      >
        {!copied ? children : copiedText}

        <span>{icon}</span>
      </Button>
    )
  },
)

CopyButton.displayName = 'CopyButton'

import { useCallback } from 'react'

interface MimetypeMapinterface {
  mimetype: string
  extension: string
}

const mimetypeMap: MimetypeMapinterface[] = [
  { mimetype: 'video/3gpp2', extension: '3g2' },
  { mimetype: 'video/3gp', extension: '3gp' },
  { mimetype: 'video/3gpp', extension: '3gp' },
  { mimetype: 'application/x-compressed', extension: '7zip' },
  { mimetype: 'audio/x-acc', extension: 'aac' },
  { mimetype: 'audio/ac3', extension: 'ac3' },
  { mimetype: 'application/postscript', extension: 'ai' },
  { mimetype: 'audio/x-aiff', extension: 'aif' },
  { mimetype: 'audio/aiff', extension: 'aif' },
  { mimetype: 'audio/x-au', extension: 'au' },
  { mimetype: 'video/x-msvideo', extension: 'avi' },
  { mimetype: 'video/msvideo', extension: 'avi' },
  { mimetype: 'video/avi', extension: 'avi' },
  { mimetype: 'application/x-troff-msvideo', extension: 'avi' },
  { mimetype: 'application/macbinary', extension: 'bin' },
  { mimetype: 'application/mac-binary', extension: 'bin' },
  { mimetype: 'application/x-binary', extension: 'bin' },
  { mimetype: 'application/x-macbinary', extension: 'bin' },
  { mimetype: 'image/bmp', extension: 'bmp' },
  { mimetype: 'image/x-bmp', extension: 'bmp' },
  { mimetype: 'image/x-bitmap', extension: 'bmp' },
  { mimetype: 'image/x-xbitmap', extension: 'bmp' },
  { mimetype: 'image/x-win-bitmap', extension: 'bmp' },
  { mimetype: 'image/x-windows-bmp', extension: 'bmp' },
  { mimetype: 'image/ms-bmp', extension: 'bmp' },
  { mimetype: 'image/x-ms-bmp', extension: 'bmp' },
  { mimetype: 'application/bmp', extension: 'bmp' },
  { mimetype: 'application/x-bmp', extension: 'bmp' },
  { mimetype: 'application/x-win-bitmap', extension: 'bmp' },
  { mimetype: 'application/cdr', extension: 'cdr' },
  { mimetype: 'application/coreldraw', extension: 'cdr' },
  { mimetype: 'application/x-cdr', extension: 'cdr' },
  { mimetype: 'application/x-coreldraw', extension: 'cdr' },
  { mimetype: 'image/cdr', extension: 'cdr' },
  { mimetype: 'image/x-cdr', extension: 'cdr' },
  { mimetype: 'zz-application/zz-winassoc-cdr', extension: 'cdr' },
  { mimetype: 'application/mac-compactpro', extension: 'cpt' },
  { mimetype: 'application/pkix-crl', extension: 'crl' },
  { mimetype: 'application/pkcs-crl', extension: 'crl' },
  { mimetype: 'application/x-x509-ca-cert', extension: 'crt' },
  { mimetype: 'application/pkix-cert', extension: 'crt' },
  { mimetype: 'text/css', extension: 'css' },
  { mimetype: 'text/x-comma-separated-values', extension: 'csv' },
  { mimetype: 'text/comma-separated-values', extension: 'csv' },
  { mimetype: 'application/vnd.msexcel', extension: 'csv' },
  { mimetype: 'application/x-director', extension: 'dcr' },
  { mimetype: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', extension: 'docx' },
  { mimetype: 'application/x-dvi', extension: 'dvi' },
  { mimetype: 'message/rfc822', extension: 'eml' },
  { mimetype: 'application/x-msdownload', extension: 'exe' },
  { mimetype: 'video/x-f4v', extension: 'f4v' },
  { mimetype: 'audio/x-flac', extension: 'flac' },
  { mimetype: 'video/x-flv', extension: 'flv' },
  { mimetype: 'image/gif', extension: 'gif' },
  { mimetype: 'application/gpg-keys', extension: 'gpg' },
  { mimetype: 'application/x-gtar', extension: 'gtar' },
  { mimetype: 'application/x-gzip', extension: 'gzip' },
  { mimetype: 'application/mac-binhex40', extension: 'hqx' },
  { mimetype: 'application/mac-binhex', extension: 'hqx' },
  { mimetype: 'application/x-binhex40', extension: 'hqx' },
  { mimetype: 'application/x-mac-binhex40', extension: 'hqx' },
  { mimetype: 'text/html', extension: 'html' },
  { mimetype: 'image/x-icon', extension: 'ico' },
  { mimetype: 'image/x-ico', extension: 'ico' },
  { mimetype: 'image/vnd.microsoft.icon', extension: 'ico' },
  { mimetype: 'text/calendar', extension: 'ics' },
  { mimetype: 'application/java-archive', extension: 'jar' },
  { mimetype: 'application/x-java-application', extension: 'jar' },
  { mimetype: 'application/x-jar', extension: 'jar' },
  { mimetype: 'image/jp2', extension: 'jp2' },
  { mimetype: 'video/mj2', extension: 'jp2' },
  { mimetype: 'image/jpx', extension: 'jp2' },
  { mimetype: 'image/jpm', extension: 'jp2' },
  { mimetype: 'image/jpeg', extension: 'jpeg' },
  { mimetype: 'image/pjpeg', extension: 'jpeg' },
  { mimetype: 'application/x-javascript', extension: 'js' },
  { mimetype: 'application/json', extension: 'json' },
  { mimetype: 'text/json', extension: 'json' },
  { mimetype: 'application/vnd.google-earth.kml+xml', extension: 'kml' },
  { mimetype: 'application/vnd.google-earth.kmz', extension: 'kmz' },
  { mimetype: 'text/x-log', extension: 'log' },
  { mimetype: 'audio/x-m4a', extension: 'm4a' },
  { mimetype: 'audio/mp4', extension: 'm4a' },
  { mimetype: 'application/vnd.mpegurl', extension: 'm4u' },
  { mimetype: 'audio/midi', extension: 'mid' },
  { mimetype: 'application/vnd.mif', extension: 'mif' },
  { mimetype: 'video/quicktime', extension: 'mov' },
  { mimetype: 'video/x-sgi-movie', extension: 'movie' },
  { mimetype: 'audio/mpeg', extension: 'mp3' },
  { mimetype: 'audio/mpg', extension: 'mp3' },
  { mimetype: 'audio/mpeg3', extension: 'mp3' },
  { mimetype: 'audio/mp3', extension: 'mp3' },
  { mimetype: 'video/mp4', extension: 'mp4' },
  { mimetype: 'video/mpeg', extension: 'mpeg' },
  { mimetype: 'application/oda', extension: 'oda' },
  { mimetype: 'audio/ogg', extension: 'ogg' },
  { mimetype: 'video/ogg', extension: 'ogg' },
  { mimetype: 'application/ogg', extension: 'ogg' },
  { mimetype: 'font/otf', extension: 'otf' },
  { mimetype: 'application/x-pkcs10', extension: 'p10' },
  { mimetype: 'application/pkcs10', extension: 'p10' },
  { mimetype: 'application/x-pkcs12', extension: 'p12' },
  { mimetype: 'application/x-pkcs7-signature', extension: 'p7a' },
  { mimetype: 'application/pkcs7-mime', extension: 'p7c' },
  { mimetype: 'application/x-pkcs7-mime', extension: 'p7c' },
  { mimetype: 'application/x-pkcs7-certreqresp', extension: 'p7r' },
  { mimetype: 'application/pkcs7-signature', extension: 'p7s' },
  { mimetype: 'application/pdf', extension: 'pdf' },
  { mimetype: 'application/octet-stream', extension: 'pdf' },
  { mimetype: 'application/x-x509-user-cert', extension: 'pem' },
  { mimetype: 'application/x-pem-file', extension: 'pem' },
  { mimetype: 'application/pgp', extension: 'pgp' },
  { mimetype: 'application/x-httpd-php', extension: 'php' },
  { mimetype: 'application/php', extension: 'php' },
  { mimetype: 'application/x-php', extension: 'php' },
  { mimetype: 'text/php', extension: 'php' },
  { mimetype: 'text/x-php', extension: 'php' },
  { mimetype: 'application/x-httpd-php-source', extension: 'php' },
  { mimetype: 'image/png', extension: 'png' },
  { mimetype: 'image/x-png', extension: 'png' },
  { mimetype: 'application/powerpoint', extension: 'ppt' },
  { mimetype: 'application/vnd.ms-powerpoint', extension: 'ppt' },
  { mimetype: 'application/vnd.ms-office', extension: 'ppt' },
  { mimetype: 'application/msword', extension: 'doc' },
  { mimetype: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', extension: 'pptx' },
  { mimetype: 'application/x-photoshop', extension: 'psd' },
  { mimetype: 'image/vnd.adobe.photoshop', extension: 'psd' },
  { mimetype: 'audio/x-realaudio', extension: 'ra' },
  { mimetype: 'audio/x-pn-realaudio', extension: 'ram' },
  { mimetype: 'application/x-rar', extension: 'rar' },
  { mimetype: 'application/rar', extension: 'rar' },
  { mimetype: 'application/x-rar-compressed', extension: 'rar' },
  { mimetype: 'audio/x-pn-realaudio-plugin', extension: 'rpm' },
  { mimetype: 'application/x-pkcs7', extension: 'rsa' },
  { mimetype: 'text/rtf', extension: 'rtf' },
  { mimetype: 'text/richtext', extension: 'rtx' },
  { mimetype: 'video/vnd.rn-realvideo', extension: 'rv' },
  { mimetype: 'application/x-stuffit', extension: 'sit' },
  { mimetype: 'application/smil', extension: 'smil' },
  { mimetype: 'text/srt', extension: 'srt' },
  { mimetype: 'image/svg+xml', extension: 'svg' },
  { mimetype: 'application/x-shockwave-flash', extension: 'swf' },
  { mimetype: 'application/x-tar', extension: 'tar' },
  { mimetype: 'application/x-gzip-compressed', extension: 'tgz' },
  { mimetype: 'image/tiff', extension: 'tiff' },
  { mimetype: 'font/ttf', extension: 'ttf' },
  { mimetype: 'text/plain', extension: 'txt' },
  { mimetype: 'text/x-vcard', extension: 'vcf' },
  { mimetype: 'application/videolan', extension: 'vlc' },
  { mimetype: 'text/vtt', extension: 'vtt' },
  { mimetype: 'audio/x-wav', extension: 'wav' },
  { mimetype: 'audio/wave', extension: 'wav' },
  { mimetype: 'audio/wav', extension: 'wav' },
  { mimetype: 'application/wbxml', extension: 'wbxml' },
  { mimetype: 'video/webm', extension: 'webm' },
  { mimetype: 'image/webp', extension: 'webp' },
  { mimetype: 'audio/x-ms-wma', extension: 'wma' },
  { mimetype: 'application/wmlc', extension: 'wmlc' },
  { mimetype: 'video/x-ms-wmv', extension: 'wmv' },
  { mimetype: 'video/x-ms-asf', extension: 'wmv' },
  { mimetype: 'font/woff', extension: 'woff' },
  { mimetype: 'font/woff2', extension: 'woff2' },
  { mimetype: 'application/xhtml+xml', extension: 'xhtml' },
  { mimetype: 'application/excel', extension: 'xl' },
  { mimetype: 'application/msexcel', extension: 'xls' },
  { mimetype: 'application/x-msexcel', extension: 'xls' },
  { mimetype: 'application/x-ms-excel', extension: 'xls' },
  { mimetype: 'application/x-excel', extension: 'xls' },
  { mimetype: 'application/x-dos_ms_excel', extension: 'xls' },
  { mimetype: 'application/xls', extension: 'xls' },
  { mimetype: 'application/x-xls', extension: 'xls' },
  { mimetype: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: 'xlsx' },
  { mimetype: 'application/vnd.ms-excel', extension: 'xlsx' },
  { mimetype: 'application/xml', extension: 'xml' },
  { mimetype: 'text/xml', extension: 'xml' },
  { mimetype: 'text/xsl', extension: 'xsl' },
  { mimetype: 'application/xspf+xml', extension: 'xspf' },
  { mimetype: 'application/x-compress', extension: 'z' },
  { mimetype: 'application/x-zip', extension: 'zip' },
  { mimetype: 'application/zip', extension: 'zip' },
  { mimetype: 'application/x-zip-compressed', extension: 'zip' },
  { mimetype: 'application/s-compressed', extension: 'zip' },
  { mimetype: 'multipart/x-zip', extension: 'zip' },
  { mimetype: 'text/x-scriptzsh', extension: 'zsh' },
]

export const useFiletype = () => {
  const getMimetype = useCallback((extension: string) => {
    const selectedItem = mimetypeMap.find((m) => m.extension === extension)

    if (!selectedItem) {
      return 'application/pdf'
    }

    return selectedItem.mimetype
  }, [])

  return { getMimetype }
}

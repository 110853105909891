import React from 'react'
import { IconProps } from '../models'

export const DiscoverIcon = ({ size, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={size || '30'}
    height={size || '6'}
    viewBox="0 0 30 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.4352 2.98667C18.438 2.34188 18.1962 1.69616 17.7097 1.20956C17.2221 0.72195 16.5747 0.48039 15.9286 0.484271C15.9255 0.484271 15.9225 0.48407 15.9193 0.48407C14.5162 0.48407 13.4033 1.59672 13.4033 3.00018C13.4033 4.3898 14.53 5.5161 15.9193 5.5161C17.3228 5.5161 18.4356 4.40325 18.4356 3.00018C18.4356 2.99557 18.4352 2.99122 18.4352 2.98667Z"
      fill="#F26E21"
    />
    <path
      d="M12.9355 4.06451C12.5594 4.44097 12.2124 4.58805 11.8385 4.59601C10.9277 4.58785 10.2581 3.91335 10.2581 2.95172C10.2581 2.46766 10.4414 2.07093 10.7317 1.73201C11.004 1.47352 11.3548 1.3065 11.7581 1.3065C12.1936 1.3065 12.5324 1.45164 12.9192 1.83874V0.725882C12.5325 0.532433 12.1935 0.435608 11.7581 0.435608C11.1431 0.479571 10.5631 0.728091 10.1183 1.11834C9.99007 1.2337 9.86722 1.36103 9.76303 1.50677C9.43809 1.92793 9.2417 2.44364 9.2417 3.0003C9.2417 4.4033 10.355 5.46777 11.7581 5.46777C11.7649 5.46777 11.7717 5.46717 11.7783 5.4671C11.7877 5.46724 11.797 5.46777 11.8064 5.46777C12.1934 5.46777 12.5324 5.37121 12.9678 5.17763L12.9355 4.06451C12.9192 4.08084 12.9517 4.09603 12.9355 4.11148V4.06451Z"
      fill="#2B2B2B"
    />
    <path
      d="M21.6121 1.12856L20.5163 3.82266L19.2581 0.628906H18.2422L20.2259 5.516H20.7582L22.8388 0.628906H21.8228L21.6121 1.12856Z"
      fill="#2B2B2B"
    />
    <path
      d="M23.2261 2.75799V5.37106H25.8391H25.8873V4.5966H25.0647H24.1938V3.72584V3.29049H25.8391V2.46765H24.1938V1.45162H25.8873V0.628906H23.2261V2.75799Z"
      fill="#2B2B2B"
    />
    <path
      d="M1.35508 0.628906H0V5.37106H1.35502C2.0807 5.37106 2.61287 5.17735 3.04835 4.83863C3.58052 4.40315 3.91938 3.72591 3.91938 3.00022C3.91944 1.59662 2.90315 0.628906 1.35508 0.628906ZM2.46787 4.20976C2.17753 4.45159 1.79043 4.5966 1.20975 4.5966H0.919404V1.45155H1.1613C1.74198 1.45155 2.12908 1.54831 2.41949 1.83852C2.75814 2.12866 2.9518 2.56441 2.9518 3.00002C3.00017 3.4355 2.80666 3.91915 2.46787 4.20976Z"
      fill="#2B2B2B"
    />
    <path d="M5.27432 0.628494H4.35498V5.37052H5.27432V0.628494Z" fill="#2B2B2B" />
    <path
      d="M7.59677 2.46769C7.01622 2.27418 6.87088 2.12891 6.87088 1.88708C6.87088 1.59674 7.16122 1.35484 7.54832 1.35484C7.83866 1.35484 8.08056 1.45166 8.32252 1.74201L8.80651 1.11301C8.41928 0.774157 7.93562 0.580841 7.40332 0.580841C6.58054 0.580841 5.9031 1.16152 5.9031 1.93546C5.9031 2.61303 6.1937 2.90331 7.06453 3.24203C7.45163 3.38696 7.59683 3.43554 7.69379 3.5323C7.88697 3.62893 7.98393 3.82264 7.98393 4.01596C7.98393 4.40313 7.69379 4.69353 7.25805 4.69353C6.82216 4.69353 6.4354 4.45164 6.24182 4.06447L5.66113 4.64515C6.09681 5.27415 6.62905 5.56443 7.30649 5.56443C8.27414 5.56443 8.95125 4.93543 8.95125 4.01596C8.95138 3.19358 8.66131 2.85486 7.59677 2.46769Z"
      fill="#2B2B2B"
    />
    <path
      d="M28.5001 3.38692C29.2257 3.24198 29.6129 2.75792 29.6129 2.0323C29.6129 1.16148 28.9838 0.628906 27.9194 0.628906H26.5161V5.37106H27.4355V3.48374H27.5808L28.8869 5.37106H29.9999L28.5001 3.38692ZM27.7257 2.80623H27.4355V1.35479H27.7257C28.3063 1.35479 28.6452 1.59662 28.6452 2.08082C28.6452 2.56447 28.3064 2.80623 27.7257 2.80623Z"
      fill="#2B2B2B"
    />
  </svg>
)

import React from 'react'
import { IconProps } from '../models'
import { getUnaryColor } from '~/assets/styles'

export const BarCodeIcon = ({ color, size, ...props }: IconProps) => (
  <svg
    aria-label="Código de barras"
    width={size || '25'}
    height={size || '24'}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="barcode">
      <path
        id="Vector"
        d="M1.75 19V5H3.875V19H1.75ZM4.75 19V5H6.75V19H4.75ZM7.75 19V5H8.75V19H7.75ZM10.75 19V5H12.75V19H10.75ZM13.75 19V5H16.75V19H13.75ZM17.75 19V5H18.75V19H17.75ZM20.75 19V5H23.75V19H20.75Z"
        fill={color ? getUnaryColor(color) : 'currentColor'}
      />
    </g>
  </svg>
)

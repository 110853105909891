import api from '~/config/api'
import { PixDataProps } from '~/pages/Pix'
import { baseChargeIntegrations } from '~/config/basePath'

const pathUrl = `${baseChargeIntegrations}/v1/debt-collection-automation`

const getPixData = async (dealId: string): Promise<string | PixDataProps> => {
  const { data } = await api.get<string>(`${pathUrl}/deals/${dealId}/pix`)

  return data
}

const getQrCode = async (pixId: string): Promise<string> => {
  const file = (
    await api.get<string>(`${pathUrl}/pix/${pixId}`, {
      responseType: 'arraybuffer',
    })
  ).data

  return Buffer.from(file, 'binary').toString('base64')
}

export { getPixData, getQrCode }

import React from 'react'
import { IconProps } from '../models'
import { getUnaryColor } from '~/assets/styles'

export const WhatsAppCallIcon = ({ color, size, ...props }: IconProps) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.62 10.79C8.06 13.62 10.38 15.93 13.21 17.38L15.41 15.18C15.68 14.91 16.08 14.82 16.43 14.94C17.55 15.31 18.76 15.51 20 15.51C20.55 15.51 21 15.96 21 16.51V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"
      fill={color ? getUnaryColor(color) : 'currentColor'}
    />
    <path
      d="M19.8342 4.164C19.0824 3.412 18.0814 3 17.0161 3C14.8211 3 13.0321 4.78001 13.0321 6.96402C13.0321 7.66403 13.2171 8.34403 13.5628 8.94403L13 11L15.1105 10.448C15.6934 10.7641 16.3488 10.9321 17.0161 10.9321C19.211 10.9321 21 9.15203 21 6.96802C21 5.90801 20.5859 4.91201 19.8342 4.164ZM17.0161 10.26C16.4211 10.26 15.8382 10.1 15.3276 9.80003L15.207 9.72803L13.9528 10.056L14.2865 8.84003L14.206 8.71603C13.8764 8.19203 13.6995 7.58402 13.6995 6.96402C13.6995 5.14801 15.1869 3.668 17.012 3.668C17.8965 3.668 18.7287 4.01201 19.3518 4.63601C19.9789 5.26001 20.3206 6.08801 20.3206 6.96802C20.3287 8.78403 18.8412 10.26 17.0161 10.26ZM18.8331 7.79603C18.7326 7.74802 18.2422 7.50802 18.1538 7.47202C18.0613 7.44002 17.997 7.42402 17.9286 7.52002C17.8603 7.62002 17.6713 7.84402 17.6151 7.90802C17.5588 7.97602 17.4985 7.98403 17.398 7.93202C17.2975 7.88403 16.9758 7.77602 16.598 7.44002C16.3005 7.17602 16.1035 6.85202 16.0432 6.75202C15.9869 6.65202 16.0351 6.60002 16.0874 6.54802C16.1316 6.50401 16.188 6.43202 16.2362 6.37601C16.2844 6.32002 16.3045 6.27601 16.3367 6.21201C16.3688 6.14401 16.3527 6.08802 16.3286 6.04002C16.3045 5.99202 16.1035 5.50402 16.0231 5.30401C15.9427 5.11201 15.8583 5.13601 15.798 5.13201C15.7377 5.13201 15.6734 5.13201 15.6051 5.13201C15.5367 5.13201 15.4322 5.15601 15.3397 5.25601C15.2513 5.35601 14.994 5.59601 14.994 6.08401C14.994 6.57201 15.3517 7.04402 15.4 7.10802C15.4482 7.17602 16.1035 8.17603 17.1005 8.60403C17.3377 8.70803 17.5226 8.76803 17.6674 8.81203C17.9045 8.88803 18.1216 8.87603 18.2944 8.85203C18.4874 8.82402 18.8854 8.61203 18.9658 8.38003C19.0502 8.14803 19.0502 7.95203 19.0221 7.90802C18.994 7.86402 18.9337 7.84402 18.8331 7.79603Z"
      fill={color ? getUnaryColor(color) : 'currentColor'}
    />
  </svg>
)

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './App.css'

import Download from './pages/Download'
import SinglePaymentDownload from './pages/SinglePaymentDownload'
import { Pix } from './pages/Pix'

const App = () => (
  <Router>
    <Switch>
      <Route path="/pix/:id">
        <Pix />
      </Route>
      <Route path="/boleto/:id">
        <SinglePaymentDownload />
      </Route>
      <Route path="/:fileName">
        <Download />
      </Route>
    </Switch>
  </Router>
)

export default App

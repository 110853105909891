import React, { forwardRef } from 'react'
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '~/assets/styles'
import { TypographyAtom, TypographyAtomProps } from './styles'

export type TypographyProps<C extends React.ElementType = 'p'> = PolymorphicComponentPropWithRef<
  C,
  TypographyAtomProps
>

type TypographyComponent = <C extends React.ElementType = 'p'>(
  props: TypographyProps<C>,
) => React.ReactElement | null

export const Typography: TypographyComponent = forwardRef(
  <C extends React.ElementType = 'p'>(props: TypographyProps<C>, ref?: PolymorphicRef<C>) => {
    const { as, variant, ...rest } = props

    const Component = as || variantMapping[variant || 'text']

    return (
      <TypographyAtom
        as={Component}
        ref={ref}
        variant={variant}
        data-testid="typography"
        {...rest}
      />
    )
  },
)

const variantMapping = {
  display: 'h1',
  titleLarge: 'h2',
  title: 'h3',
  titleSmall: 'h4',
  textLarge: 'p',
  text: 'p',
  textSmall: 'p',
  textXSmall: 'p',
  caption: 'span',
} as const

import styled from 'styled-components'

import { breakpoints, colors } from '~/assets/styles'

export const Header = styled.header`
  display: flex;
  justify-content: center;
  padding: 24px;
  background-color: ${colors.background.white};
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 40px 0;
`

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 862px;
  padding: 24px 0 16px 0;
  transition: 0.5s;

  @media (max-width: ${breakpoints.lg}px) {
    width: 85%;
  }

  @media (max-width: ${breakpoints.md}px) {
    width: 90%;
  }
`

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`

export const Ol = styled.ol`
  padding: 0px 0px 0px 22px;

  li {
    margin: 8px 0 0 0;
  }
`

import React from 'react'
import { IconProps } from '../models'

export const VisaIcon = ({ size, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={size || '30'}
    height={size || '10'}
    viewBox="0 0 30 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.9824 0.52626H26.0964C25.5263 0.52626 25.0877 0.701699 24.8245 1.27187L21.228 9.47363H23.7719C23.7719 9.47363 24.2105 8.37714 24.2982 8.11398C24.5614 8.11398 27.0614 8.11398 27.4122 8.11398C27.5 8.421 27.7193 9.42977 27.7193 9.42977H30L27.9824 0.52626ZM25 6.27187C25.2193 5.74556 25.9649 3.77187 25.9649 3.77187C25.9649 3.81573 26.1842 3.24556 26.2719 2.93854L26.4473 3.72801C26.4473 3.72801 26.9298 5.87714 27.0175 6.31573H25V6.27187Z"
      fill="#3362AB"
    />
    <path
      d="M21.4034 6.53506C21.4034 8.37717 19.7367 9.60524 17.149 9.60524C16.0525 9.60524 14.9999 9.38594 14.4297 9.12278L14.7806 7.10524L15.0876 7.23682C15.8771 7.5877 16.4034 7.71927 17.3683 7.71927C18.07 7.71927 18.8157 7.45612 18.8157 6.84208C18.8157 6.44735 18.5086 6.18419 17.5437 5.74559C16.6227 5.30699 15.3946 4.60524 15.3946 3.33331C15.3946 1.57892 17.1051 0.394714 19.5174 0.394714C20.4385 0.394714 21.2279 0.570153 21.7104 0.789451L21.3595 2.71928L21.1841 2.54384C20.7455 2.3684 20.1753 2.19296 19.342 2.19296C18.4209 2.23682 17.9823 2.63156 17.9823 2.98243C17.9823 3.37717 18.5086 3.68419 19.342 4.07892C20.7455 4.73682 21.4034 5.48243 21.4034 6.53506Z"
      fill="#3362AB"
    />
    <path
      d="M0 0.614037L0.0438596 0.438599H3.81579C4.3421 0.438599 4.73684 0.614037 4.86842 1.18421L5.70175 5.13158C4.86842 3.02632 2.9386 1.31579 0 0.614037Z"
      fill="#F9B50B"
    />
    <path
      d="M11.0086 0.526297L7.19281 9.42981H4.60509L2.41211 1.97367C3.99106 2.98244 5.30685 4.56138 5.7893 5.65788L6.05246 6.57893L8.42088 0.482437H11.0086V0.526297Z"
      fill="#3362AB"
    />
    <path d="M12.0175 0.482437H14.4298L12.8947 9.42981H10.4824L12.0175 0.482437Z" fill="#3362AB" />
  </svg>
)

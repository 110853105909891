import React from 'react'
import { IconProps } from '../models'
import { getUnaryColor } from '~/assets/styles'

export const RGIcon = ({ color, size, ...props }: IconProps) => (
  <svg
    width={size || '16'}
    height={size || '11'}
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.662 10V0.843999H3.756C4.39067 0.843999 4.96 0.927999 5.464 1.096C5.97733 1.264 6.37867 1.54867 6.668 1.95C6.96667 2.342 7.116 2.87867 7.116 3.56C7.116 4.26 6.948 4.82467 6.612 5.254C6.28533 5.68333 5.84667 5.99133 5.296 6.178L7.48 10H5.66L3.686 6.416H2.286V10H0.662ZM2.286 5.128H3.588C4.21333 5.128 4.68933 4.99733 5.016 4.736C5.352 4.46533 5.52 4.07333 5.52 3.56C5.52 3.03733 5.352 2.67333 5.016 2.468C4.68933 2.25333 4.21333 2.146 3.588 2.146H2.286V5.128ZM12.7832 10.168C11.9618 10.168 11.2245 9.986 10.5712 9.622C9.91782 9.258 9.40449 8.726 9.03116 8.026C8.65782 7.31667 8.47116 6.458 8.47116 5.45C8.47116 4.45133 8.66249 3.59733 9.04516 2.888C9.42782 2.16933 9.94582 1.62333 10.5992 1.25C11.2525 0.867333 11.9852 0.676 12.7972 0.676C13.4505 0.676 14.0012 0.797333 14.4492 1.04C14.9065 1.27333 15.2798 1.53933 15.5692 1.838L14.7012 2.888C14.4678 2.664 14.2065 2.47733 13.9172 2.328C13.6372 2.16933 13.2825 2.09 12.8532 2.09C12.0412 2.09 11.3832 2.38867 10.8792 2.986C10.3845 3.574 10.1372 4.38133 10.1372 5.408C10.1372 6.45333 10.3752 7.27467 10.8512 7.872C11.3272 8.46 12.0225 8.754 12.9372 8.754C13.1985 8.754 13.4458 8.72133 13.6792 8.656C13.9125 8.58133 14.1038 8.47867 14.2532 8.348V6.374H12.5172V5.044H15.7092V9.076C15.4012 9.384 14.9905 9.64533 14.4772 9.86C13.9732 10.0653 13.4085 10.168 12.7832 10.168Z"
      fill={color ? getUnaryColor(color) : 'currentColor'}
    />
  </svg>
)

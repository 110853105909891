import React from 'react'
import { IconProps } from '../models'
import { getUnaryColor } from '~/assets/styles'

export const CaixaIcon = ({ color, size, ...props }: IconProps) => (
  <svg
    aria-label="Banco caixa"
    width={size || '16'}
    height={size || '17'}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity="0.01" y="0.5" width="16" height="16" fill="#71727D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5038 3.16663H15.1161L10.3107 8.21508H5.69727L10.5038 3.16663Z"
      fill={color ? getUnaryColor(color) : 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.70821 8.24166H10.2902L7.78634 3.22278H3.17139L5.70821 8.24166Z"
      fill="#71727D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.94231 8.72351L12.4791 13.7424H7.86418L5.36035 8.72351H9.94231Z"
      fill="#71727D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.1455 13.772H0.533203L5.33855 8.72351L9.95202 8.72351L5.1455 13.772Z"
      fill={color ? getUnaryColor(color) : 'currentColor'}
    />
  </svg>
)

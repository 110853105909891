import React, { forwardRef } from 'react'
import { PolymorphicComponentPropWithRef, PolymorphicRef } from '~/assets/styles'
import { ButtonAtom, ButtonAtomProps } from './styles'

export type ButtonProps<C extends React.ElementType = 'button'> = PolymorphicComponentPropWithRef<
  C,
  { icon?: JSX.Element } & ButtonAtomProps
>

type ButtonComponent = <C extends React.ElementType = 'button'>(
  props: ButtonProps<C>,
) => React.ReactElement | null

export const Button: ButtonComponent = forwardRef(
  <C extends React.ElementType = 'button'>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => {
    const {
      as, icon, children, ...rest
    } = props

    return (
      <ButtonAtom as={as || 'button'} ref={ref} type="button" data-testid="button" {...rest}>
        {children}
        {icon && <span>{icon}</span>}
      </ButtonAtom>
    )
  },
)

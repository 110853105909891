import styled, { css } from 'styled-components'
import {
  SxProps,
  borderRadius,
  colors,
  spacings,
  sxProps,
  transitions,
  typography,
} from '~/assets/styles'

const { background, error, info, grey, primary, secondary, success, warning } = colors

const buttonColors = {
  primary,
  negative: error,
  info,
  secondary,
  positive: success,
  warning,
  neutral: grey,
} as const

export type ButtonAtomProps = {
  color?: keyof typeof buttonColors
  fullWidth?: boolean
  size?: 'medium' | 'small' | 'smaller' | 'large'
  variant?: 'contained' | 'outlined' | 'ghost' | 'text'
  dark?: boolean
} & SxProps

export const ButtonAtom = styled.button<ButtonAtomProps>`
  align-items: center;
  border: 1px solid transparent;
  border-radius: ${borderRadius.small};
  display: inline-flex;
  font-size: ${typography.text};
  font-weight: 600;
  line-height: 140%;
  gap: ${spacings[8]};
  justify-content: center;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ${transitions.easeInOut}, outline 0.1s;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};

  &:hover {
    text-decoration: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  ${({ size, variant }) => makeSizes(variant)[size || 'medium']}
  ${(props) => getVariant(props)}
  
  & > span {
    display: inherit;
  }

  ${sxProps}
`

const makeSizes = (variant: ButtonAtomProps['variant']) => ({
  smaller: css`
    font-size: ${typography.textSmall};
    line-height: 120%;
    padding: ${spacings[4]} ${spacings[12]};
  `,
  medium: css`
    padding: ${spacings[8]} ${spacings[16]};
  `,
  small: css`
    font-size: ${variant === 'text' ? typography.textSmall : typography.text};
    font-weight: ${variant === 'text' ? 400 : 600};
    line-height: ${variant === 'text' ? '140%' : '150%'};
    padding: ${spacings[4]} ${spacings[16]};
  `,
  large: css`
    font-size: ${typography.textLarge};
    padding: ${spacings[12]} ${spacings[32]};
  `,
})

const getVariant = (props: ButtonAtomProps) => {
  const color = getColor(props.color)

  const variants = {
    contained: css`
      background-color: ${color.base};
      color: ${grey.white};

      &:not(:disabled) {
        &:hover {
          background-color: ${color.dark};
        }
        &:active {
          background-color: ${color.darker};
        }
        &:focus-visible {
          outline: 3px solid ${color.darker};
        }
      }
      &:disabled {
        background-color: ${background.grey};
        color: ${grey.light};
      }
    `,
    outlined: css`
      border-color: ${color.base};
      background-color: transparent;
      color: ${color.base};

      &:not(:disabled) {
        ${getOutlinedVariantEffects(props.color, props.dark)}

        &:focus-visible {
          outline: 2px solid ${color.darker};
        }
      }
      &:disabled {
        border-color: ${grey.light};
        color: ${grey.light};
      }
    `,
    ghost: css`
      color: ${color.base};

      &:not(:disabled) {
        &:hover {
          background-color: ${color.base};
          color: ${grey.white};
        }
        &:active {
          background-color: ${color.dark};
          color: ${grey.white};
        }
        &:focus-visible {
          outline: 3px solid ${color.dark};
        }
      }

      &:disabled {
        color: ${grey.light};
      }
    `,
    text: css`
      color: ${color.base};
      padding: unset;

      &:not(:disabled) {
        &:hover {
          color: ${color.dark};
        }
        &:active {
          color: ${color.darker};
        }
        &:focus-visible {
          outline: 3px solid ${color.darker};
        }
      }

      &:disabled {
        color: ${grey.light};
      }
    `,
  }

  return variants[props.variant || 'contained']
}

export const getColor = (colorKey: ButtonAtomProps['color']) => buttonColors[colorKey || 'primary']

const getOutlinedVariantEffects = (
  colorKey: ButtonAtomProps['color'],
  dark: ButtonAtomProps['dark'],
) => {
  const color = getColor(colorKey)

  if (dark) {
    const feedbackBaseStyles = css`
      border-color: ${color.darker};
      color: ${color.darker};

      &:hover {
        background-color: ${color.base};
        border-color: ${color.base};
      }
      &:active {
        background-color: ${color.dark};
        border-color: ${color.dark};
        color: ${grey.white};
      }
    `

    if (colorKey === 'warning') return feedbackBaseStyles
    if (colorKey && ['positive', 'secondary', 'info', 'negative'].includes(colorKey)) {
      return css`
        ${feedbackBaseStyles}

        &:hover {
          color: ${grey.white};
        }
      `
    }
  }

  return css`
    &:hover {
      background-color: ${color.base};
      border-color: ${color.base};
      color: ${grey.white};
    }
    &:active {
      background-color: ${color.dark};
      border-color: ${color.dark};
      color: ${grey.white};
    }
  `
}

import React from 'react'

import { Flex, FlexProps } from '../Flex'

import { icons, IconProps as BaseIconProps } from '~/assets/ds-icons'
import { BorderRadiusValue, getUnaryColor } from '~/assets/styles'

export type IconProps = {
  name: keyof typeof icons
  backgroundColor?: string
  borderRadius?: BorderRadiusValue
  ratio?: number
  slotProps?: {
    root?: FlexProps
  }
} & BaseIconProps

const handleSize = (size?: string | number) => {
  if (typeof size === 'number') return `${size}px`
  return size
}

export const Icon = (props: IconProps) => {
  const {
    name,
    color,
    backgroundColor,
    borderRadius = '50%',
    ratio = backgroundColor ? 0.5 : 1,
    slotProps,
    ...rest
  } = props

  const { icon: IconComponent, ariaLabel } = icons[name]
  const iconSize = `${ratio * 100}%`

  const iconComponent = (
    <IconComponent
      aria-label={ariaLabel}
      color={getUnaryColor(color)}
      data-testid="icon"
      {...rest}
    />
  )

  return backgroundColor ? (
    <Flex
      as="span"
      alignItems="center"
      justifyContent="center"
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      width={handleSize(rest.width) || handleSize(rest.size) || 'fit-content'}
      height={handleSize(rest.height) || handleSize(rest.size) || 'auto'}
      {...slotProps?.root}
      sx={{
        svg: {
          width: iconSize,
          height: iconSize,
        },
        ...slotProps?.root?.sx,
      }}
      data-testid="icon-wrapper"
    >
      {iconComponent}
    </Flex>
  ) : (
    iconComponent
  )
}

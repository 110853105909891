import React from 'react'
import { IconProps } from '../models'

export const MastercardIcon = ({ size, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={size || '30'}
    height={size || '20'}
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.7556 2.89553L11.0088 2.92216L11.2435 17.1045L18.9903 17.0779L18.7556 2.89553Z"
      fill="#FF5F00"
    />
    <path
      d="M11.5187 10.042C11.4708 7.15739 12.763 4.59433 14.787 2.9318C13.2592 1.73297 11.3439 1.01207 9.26773 1.01918C4.34906 1.03602 0.436739 5.08807 0.51969 10.0796C0.60264 15.0712 4.64918 19.096 9.56785 19.0792C11.6441 19.0721 13.5352 18.3381 15.0229 17.129C12.9443 15.5056 11.5667 12.9266 11.5187 10.042Z"
      fill="#EB001B"
    />
    <path
      d="M29.4797 9.92042C29.5626 14.912 25.6503 18.964 20.7316 18.9809C18.6554 18.988 16.7401 18.2671 15.2124 17.0683C17.2611 15.4057 18.5286 12.8427 18.4806 9.95809C18.4327 7.07351 17.0554 4.51959 14.9764 2.87112C16.4642 1.66195 18.3553 0.928015 20.4315 0.920904C25.3502 0.904058 29.3971 4.95393 29.4797 9.92042Z"
      fill="#F79E1B"
    />
  </svg>
)

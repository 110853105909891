export const colors = {
  primary: {
    darker: '#0059B2',
    dark: '#0073E5',
    base: '#198CFF',
    light: '#D1E8FF',
    lighter: '#E9F4FF',
  },
  secondary: {
    darker: '#994D00',
    dark: '#CC6700',
    base: '#FF9933',
    light: '#FFEBD6',
    lighter: '#FFF5EB',
  },
  grey: {
    darker: '#252433',
    dark: '#4D4F5C',
    base: '#71727D',
    light: '#B9C2CB',
    lighter: '#DCE1E5',
    lightest: '#EDEFF1',
    blueish: '#F5F6FA',
    white: '#FFFFFF',
  },
  error: {
    darker: '#990300',
    dark: '#CC0500',
    base: '#FF3833',
    light: '#FF9B98',
    lighter: '#FFECEB',
  },
  warning: {
    darker: '#997F00',
    dark: '#CCAA00',
    base: '#FFDD33',
    light: '#FFED98',
    lighter: '#FFFCEB',
  },
  success: {
    darker: '#1F7A2E',
    dark: '#29A33D',
    base: '#5CD670',
    light: '#ADEAB7',
    lighter: '#EFFBF1',
  },
  info: {
    darker: '#003BB2',
    dark: '#004CE5',
    base: '#1966FF',
    light: '#98BAFF',
    lighter: '#E5EEFF',
  },
  background: {
    grey: '#F4F5F7',
    system: '#F5F6FA',
    card: '#F3F9FF',
    white: '#FFFFFF',
  },
} as const

export type Colors = Omit<typeof colors, 'background'>
export type ColorsKeys = keyof Colors

export const getUnaryColor = (color?: string) => {
  if (!color?.includes('.')) return color
  return colors[color.split('.')[0] as keyof Colors][
    color.split('.')[1] as keyof Colors[keyof Colors]
  ]
}

import React from 'react'
import { IconProps } from '../models'

export const WhatsAppIcon = ({ size, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={size || '20'}
    height={size || '21'}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="logos:whatsapp-icon" clipPath="url(#clip0_1158_4085)">
      <path
        id="Vector"
        d="M0.429081 10.3804C0.428612 12.0608 0.871 13.7016 1.71219 15.1478L0.348633 20.089L5.44359 18.7631C6.85279 19.5245 8.43169 19.9235 10.0362 19.9236H10.0404C15.3371 19.9236 19.6487 15.6458 19.651 10.3879C19.652 7.8401 18.6532 5.44428 16.8385 3.6418C15.0241 1.83948 12.6111 0.846378 10.04 0.845215C4.74267 0.845215 0.431346 5.12273 0.429159 10.3804"
        fill="url(#paint0_linear_1158_4085)"
      />
      <path
        id="Vector_2"
        d="M0.0865022 10.3772C0.0859555 12.1181 0.544198 13.8175 1.41538 15.3155L0.00292969 20.4339L5.28057 19.0605C6.73474 19.8474 8.37198 20.2622 10.038 20.2629H10.0423C15.529 20.2629 19.9955 15.8312 19.9979 10.3851C19.9988 7.74573 18.9641 5.2638 17.0845 3.39674C15.2048 1.52992 12.7054 0.501085 10.0423 0.5C4.55459 0.5 0.0886892 4.93101 0.0865022 10.3772ZM3.22945 15.0575L3.03239 14.7471C2.20401 13.4398 1.76678 11.9291 1.7674 10.3778C1.7692 5.85108 5.48107 2.16822 10.0454 2.16822C12.2558 2.16915 14.333 3.02434 15.8955 4.57597C17.4578 6.12775 18.3175 8.19054 18.317 10.3845C18.3149 14.9112 14.603 18.5946 10.0423 18.5946H10.039C8.55396 18.5938 7.09753 18.198 5.82739 17.45L5.52512 17.2721L2.39326 18.0871L3.22945 15.0574V15.0575Z"
        fill="url(#paint1_linear_1158_4085)"
      />
      <path
        id="Vector_3"
        d="M7.55335 6.24801C7.36699 5.83692 7.17087 5.82863 6.99365 5.82142C6.84853 5.81522 6.68263 5.81568 6.5169 5.81568C6.351 5.81568 6.08146 5.87762 5.85363 6.12452C5.62556 6.37165 4.98291 6.96886 4.98291 8.18351C4.98291 9.39824 5.87432 10.5721 5.99859 10.737C6.12301 10.9016 7.71948 13.474 10.2479 14.4636C12.3492 15.286 12.7769 15.1224 13.2329 15.0812C13.6891 15.0401 14.7047 14.4841 14.912 13.9076C15.1193 13.3312 15.1193 12.8371 15.0571 12.7338C14.995 12.631 14.8291 12.5692 14.5803 12.4458C14.3315 12.3223 13.1085 11.725 12.8805 11.6426C12.6524 11.5603 12.4866 11.5192 12.3207 11.7664C12.1548 12.0132 11.6785 12.5692 11.5333 12.7338C11.3882 12.8989 11.243 12.9194 10.9944 12.7959C10.7454 12.672 9.94431 12.4116 8.99384 11.5706C8.25434 10.9162 7.7551 10.1081 7.60998 9.86088C7.46486 9.61405 7.59443 9.48026 7.71917 9.35723C7.83094 9.24661 7.96801 9.06894 8.09251 8.92483C8.21654 8.78064 8.25794 8.67777 8.34089 8.51312C8.42391 8.34832 8.38236 8.20413 8.32027 8.08064C8.25794 7.95715 7.77454 6.73615 7.55335 6.24801Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1158_4085"
        x1="965.467"
        y1="1925.22"
        x2="965.467"
        y2="0.845215"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1FAF38" />
        <stop offset="1" stopColor="#60D669" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1158_4085"
        x1="999.749"
        y1="1993.89"
        x2="999.749"
        y2="0.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9F9F9" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_1158_4085">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
)

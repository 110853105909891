import {
  MdAccessibilityNew as AccessibilityNewIcon,
  MdAddBusiness as AddBusinessIcon,
  MdAdd as AddIcon,
  MdApproval as ApprovalIcon,
  MdArrowBack as ArrowBackIcon,
  MdArrowRightAlt as ArrowRightAltIcon,
  MdArtTrack as ArtTrackIcon,
  MdAssignment as AssignmentIcon,
  MdAssignmentInd as AssignmentIndIcon,
  MdAssignmentTurnedIn as AssignmentTurnedInIcon,
  MdAttachFile as AttachFileIcon,
  MdAttachMoney as AttachMoneyIcon,
  MdBlock as BlockIcon,
  MdCached as CachedIcon,
  MdCake as CakeIcon,
  MdCancel as CancelIcon,
  MdCheckCircle as CheckCircleIcon,
  MdCheckCircleOutline as CheckCircleOutlineIcon,
  MdCircle as CircleIcon,
  MdClose as CloseIcon,
  MdContentCopy as ContentCopyIcon,
  MdCreditCard as CreditCardIcon,
  MdCreditScore as CreditScoreIcon,
  MdCurrencyExchange as CurrencyExchangeIcon,
  MdDelete as DeleteIcon,
  MdDeleteOutline as DeleteOutlineIcon,
  MdDirectionsCar as DirectionsCarIcon,
  MdDone as DoneIcon,
  MdDoneAll as DoneAllIcon,
  MdDownload as DownloadIcon,
  MdDriveEta as DriveEtaIcon,
  MdEdit as EditIcon,
  MdExpandLess as ExpandLessIcon,
  MdExpandMore as ExpandMoreIcon,
  MdFemale as FemaleIcon,
  MdFilterAlt as FilterAltIcon,
  MdHandshake as HandShakeIcon,
  MdHelpOutline as HelpOutlineIcon,
  MdHistory as HistoryIcon,
  MdHome as HomeIcon,
  MdHouse as HouseIcon,
  MdInfo as InfoIcon,
  MdInfoOutline as InfoOutlineIcon,
  MdKeyboardArrowLeft as KeyboardArrowLeftIcon,
  MdKeyboardArrowRight as KeyboardArrowRightIcon,
  MdKeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
  MdKeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
  MdList as ListIcon,
  MdLocalAtm as LocalAtmIcon,
  MdLocalShipping as LocalShippingIcon,
  MdLocationCity as LocationCityIcon,
  MdMail as MailIcon,
  MdMale as MaleIcon,
  MdManageSearch as ManageSearchIcon,
  MdMoreHoriz as MoreHorizIcon,
  MdNotifications as NotificationsIcon,
  MdOutlineNotifications as NotificationsOutlineIcon,
  MdOpenInNew as OpenInNewIcon,
  MdOutlineAgriculture as OutlineAgricultureIcon,
  MdOutlineBadge as OutlineBadgeIcon,
  MdOutlineCalendarMonth as OutlineCalendarMonthIcon,
  MdOutlineCalendarToday as OutlineCalendarTodayIcon,
  MdOutlineCall as OutlineCallIcon,
  MdOutlineCameraAlt as OutlineCameraAltIcon,
  MdOutlineCancel as OutlineCancelIcon,
  MdOutlineContactPhone as OutlineContactPhoneIcon,
  MdOutlineDelete as OutlineDeleteIcon,
  MdOutlineDescription as OutlineDescriptionIcon,
  MdOutlineDownload as OutlineDownloadIcon,
  MdOutlineDrafts as OutlineDraftsIcon,
  MdOutlineDraw as OutlineDrawIcon,
  MdOutlineEmail as OutlineEmailIcon,
  MdOutlineFileCopy as OutlineFileCopyIcon,
  MdOutlineFileDownload as OutlineFileDownloadIcon,
  MdOutlineFilterAlt as OutlineFilterAltIcon,
  MdOutlineHandshake as OutlineHandShakeIcon,
  MdOutlineHistoryEdu as OutlineHistoryEduIcon,
  MdOutlineHome as OutlineHomeIcon,
  MdOutlineHomeWork as OutlineHomeWorkIcon,
  MdOutlineHowToReg as OutlineHowToRegIcon,
  MdOutlineModeEdit as OutlineModeEditIcon,
  MdOutlinePaid as OutlinePaidIcon,
  MdOutlinePayments as OutlinePaymentsIcon,
  MdOutlinePeopleAlt as OutlinePeopleAltIcon,
  MdOutlinePerson as OutlinePersonIcon,
  MdOutlineReport as OutlineReportIcon,
  MdOutlineSaveAlt as OutlineSaveAltIcon,
  MdOutlineSavings as OutlineSavingsIcon,
  MdOutlineSend as OutlineSendIcon,
  MdOutlineShare as OutlineShareIcon,
  MdOutlineSummarize as OutlineSummarizeIcon,
  MdOutlineVisibility as OutlineVisibilityIcon,
  MdPhoneAndroid as PhoneAndroidIcon,
  MdPhoneCallback as PhoneCallbackIcon,
  MdPhoneForwarded as PhoneForwardedIcon,
  MdPix as PixIcon,
  MdPointOfSale as PointOfSaleIcon,
  MdPriceCheck as PriceCheckIcon,
  MdPriorityHigh as PriorityHighIcon,
  MdQueryStats as QueryStatsIcon,
  MdReceiptLong as ReceiptLongIcon,
  MdRecommend as RecommendIcon,
  MdRefresh as RefreshIcon,
  MdRemove as RemoveIcon,
  MdReport as ReportIcon,
  MdRotateLeft as RotateLeftIcon,
  MdSchedule as ScheduleIcon,
  MdSearch as SearchIcon,
  MdSelectAll as SelectAllIcon,
  MdSms as SmsIcon,
  MdSentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  MdSentimentDissatisfied as SentimentDissatisfiedIcon,
  MdSentimentNeutral as SentimentNeutralIcon,
  MdSentimentSatisfied as SentimentSatisfiedIcon,
  MdSentimentVerySatisfied as SentimentVerySatisfiedIcon,
  MdStorefront as StorefrontIcon,
  MdTwoWheeler as TwoWheelerIcon,
  MdUpdate as UpdateIcon,
  MdUpload as UploadIcon,
  MdVisibility as VisibilityIcon,
  MdVisibilityOff as VisibilityOffIcon,
  MdVolunteerActivism as VolunteerActivismIcon,
  MdWarning as WarningIcon,
  MdWarningAmber as WarningOutlineIcon,
  MdWatchLater as WatchLaterIcon,
  MdWorkOutline as WorkOutlineIcon,
} from 'react-icons/md'
import {
  RiFacebookCircleFill as FacebookIcon,
  RiInstagramFill as InstagramIcon,
} from 'react-icons/ri'
import {
  AmericanExpressIcon,
  BarCodeIcon,
  CaixaIcon,
  DinersClubIcon,
  DiscoverIcon,
  EloIcon,
  FileExcelOutlineIcon,
  GiftIcon,
  HipercardIcon,
  MastercardIcon,
  OutlineWhatsAppIcon,
  RGIcon,
  VisaIcon,
  WhatsAppCallIcon,
  WhatsAppIcon,
} from './custom'

export * from './models'

export const filledIcons = {
  AccessibilityNew: {
    icon: AccessibilityNewIcon,
    ariaLabel: 'Acessibilidade',
  },
  AddBusiness: {
    icon: AddBusinessIcon,
    ariaLabel: 'Adicionar',
  },
  Add: {
    icon: AddIcon,
    ariaLabel: 'Adicionar',
  },
  Approval: {
    icon: ApprovalIcon,
    ariaLabel: 'Aprovar',
  },
  ArrowBack: {
    icon: ArrowBackIcon,
    ariaLabel: 'Voltar',
  },
  ArrowRightAlt: {
    icon: ArrowRightAltIcon,
    ariaLabel: 'Avançar',
  },
  ArtTrack: {
    icon: ArtTrackIcon,
    ariaLabel: 'Arte',
  },
  Assignment: {
    icon: AssignmentIcon,
    ariaLabel: 'Tarefa',
  },
  AssignmentInd: {
    icon: AssignmentIndIcon,
    ariaLabel: 'Tarefa',
  },
  AssignmentTurnedIn: {
    icon: AssignmentTurnedInIcon,
    ariaLabel: 'Concluído',
  },
  AttachFile: {
    icon: AttachFileIcon,
    ariaLabel: 'Arquivo',
  },
  AttachMoney: {
    icon: AttachMoneyIcon,
    ariaLabel: 'Dinheiro',
  },
  BarCode: {
    icon: BarCodeIcon,
    ariaLabel: 'Código de barras',
  },
  Block: {
    icon: BlockIcon,
    ariaLabel: 'Bloquear',
  },
  Cached: {
    icon: CachedIcon,
    ariaLabel: 'Atualizar',
  },
  Cake: {
    icon: CakeIcon,
    ariaLabel: 'Feliz Aniversário',
  },
  Cancel: {
    icon: CancelIcon,
    ariaLabel: 'Cancelar',
  },
  Circle: {
    icon: CircleIcon,
    ariaLabel: 'Círculo',
  },
  CheckCircle: {
    icon: CheckCircleIcon,
    ariaLabel: 'Sucesso',
  },
  Close: {
    icon: CloseIcon,
    ariaLabel: 'Fechar',
  },
  ContentCopy: {
    icon: ContentCopyIcon,
    ariaLabel: 'Copiar',
  },
  CreditCard: {
    icon: CreditCardIcon,
    ariaLabel: 'Cartão de crédito',
  },
  CreditScore: {
    icon: CreditScoreIcon,
    ariaLabel: 'Score',
  },
  CurrencyExchange: {
    icon: CurrencyExchangeIcon,
    ariaLabel: 'Câmbio',
  },
  Delete: {
    icon: DeleteIcon,
    ariaLabel: 'Deletar',
  },
  DirectionsCar: {
    icon: DirectionsCarIcon,
    ariaLabel: 'Carro',
  },
  Done: {
    icon: DoneIcon,
    ariaLabel: 'Concluído',
  },
  DoneAll: {
    icon: DoneAllIcon,
    ariaLabel: 'Lido',
  },
  Download: {
    icon: DownloadIcon,
    ariaLabel: 'Baixar',
  },
  DriveEta: {
    icon: DriveEtaIcon,
    ariaLabel: 'Carro',
  },
  Edit: {
    icon: EditIcon,
    ariaLabel: 'Editar',
  },
  ExpandLess: {
    icon: ExpandLessIcon,
    ariaLabel: 'Recolher',
  },
  ExpandMore: {
    icon: ExpandMoreIcon,
    ariaLabel: 'Expandir',
  },
  Facebook: {
    icon: FacebookIcon,
    ariaLabel: 'Facebook',
  },
  Female: {
    icon: FemaleIcon,
    ariaLabel: 'Feminino',
  },
  FilterAlt: {
    icon: FilterAltIcon,
    ariaLabel: 'Filtro',
  },
  Gift: {
    icon: GiftIcon,
    ariaLabel: 'Presente',
  },
  HandShake: {
    icon: HandShakeIcon,
    ariaLabel: 'Aperto de mão',
  },
  History: {
    icon: HistoryIcon,
    ariaLabel: 'Histórico',
  },
  Home: {
    icon: HomeIcon,
    ariaLabel: 'Início',
  },
  House: {
    icon: HouseIcon,
    ariaLabel: 'Casa',
  },
  Info: {
    icon: InfoIcon,
    ariaLabel: 'Informação',
  },
  Instagram: {
    icon: InstagramIcon,
    ariaLabel: 'Instagram',
  },
  KeyboardArrowLeft: {
    icon: KeyboardArrowLeftIcon,
    ariaLabel: 'Voltar',
  },
  KeyboardArrowRight: {
    icon: KeyboardArrowRightIcon,
    ariaLabel: 'Avançar',
  },
  KeyboardDoubleArrowLeft: {
    icon: KeyboardDoubleArrowLeftIcon,
    ariaLabel: 'Voltar pro Início',
  },
  KeyboardDoubleArrowRight: {
    icon: KeyboardDoubleArrowRightIcon,
    ariaLabel: 'Avançar pro Fim',
  },
  List: {
    icon: ListIcon,
    ariaLabel: 'Lista',
  },
  LocalAtm: {
    icon: LocalAtmIcon,
    ariaLabel: 'Caixa eletrônico',
  },
  LocalShipping: {
    icon: LocalShippingIcon,
    ariaLabel: 'Caminhão',
  },
  LocationCity: {
    icon: LocationCityIcon,
    ariaLabel: 'Cidade',
  },
  Mail: {
    icon: MailIcon,
    ariaLabel: 'E-mail',
  },
  Male: {
    icon: MaleIcon,
    ariaLabel: 'Masculino',
  },
  ManageSearch: {
    icon: ManageSearchIcon,
    ariaLabel: 'Pesquisar',
  },
  MoreHoriz: {
    icon: MoreHorizIcon,
    ariaLabel: 'Mais',
  },
  Notification: {
    icon: NotificationsIcon,
    ariaLabel: 'Notificação',
  },
  OpenInNew: {
    icon: OpenInNewIcon,
    ariaLabel: 'Abrir em nova aba',
  },
  OutlineAgriculture: {
    icon: OutlineAgricultureIcon,
    ariaLabel: 'Agricultura',
  },
  PhoneAndroid: {
    icon: PhoneAndroidIcon,
    ariaLabel: 'Telefone',
  },
  PhoneCallback: {
    icon: PhoneCallbackIcon,
    ariaLabel: 'Telefone',
  },
  PhoneForwarded: {
    icon: PhoneForwardedIcon,
    ariaLabel: 'Telefone',
  },
  Pix: {
    icon: PixIcon,
    ariaLabel: 'Pix',
  },
  PointOfSale: {
    icon: PointOfSaleIcon,
    ariaLabel: 'Ponto de venda',
  },
  PriceCheck: {
    icon: PriceCheckIcon,
    ariaLabel: 'Preço',
  },
  PriorityHigh: {
    icon: PriorityHighIcon,
    ariaLabel: 'Alta Prioridade',
  },
  QueryStats: {
    icon: QueryStatsIcon,
    ariaLabel: 'Estatísticas',
  },
  ReceiptLong: {
    icon: ReceiptLongIcon,
    ariaLabel: 'Recibo',
  },
  Recommend: {
    icon: RecommendIcon,
    ariaLabel: 'Recomendar',
  },
  Refresh: {
    icon: RefreshIcon,
    ariaLabel: 'Atualizar',
  },
  Remove: {
    icon: RemoveIcon,
    ariaLabel: 'Remover',
  },
  Report: {
    icon: ReportIcon,
    ariaLabel: 'Erro',
  },
  RG: {
    icon: RGIcon,
    ariaLabel: 'RG',
  },
  RotateLeft: {
    icon: RotateLeftIcon,
    ariaLabel: 'Girar para esquerda',
  },
  Schedule: {
    icon: ScheduleIcon,
    ariaLabel: 'Agendar',
  },
  Search: {
    icon: SearchIcon,
    ariaLabel: 'Pesquisar',
  },
  SelectAll: {
    icon: SelectAllIcon,
    ariaLabel: 'Selecionar tudo',
  },
  Sms: {
    icon: SmsIcon,
    ariaLabel: 'SMS',
  },
  SentimentVeryDissatisfied: {
    icon: SentimentVeryDissatisfiedIcon,
    ariaLabel: 'Muito insatisfeito',
  },
  SentimentDissatisfied: {
    icon: SentimentDissatisfiedIcon,
    ariaLabel: 'Insatisfeito',
  },
  SentimentNeutral: {
    icon: SentimentNeutralIcon,
    ariaLabel: 'Neutro',
  },
  SentimentSatisfied: {
    icon: SentimentSatisfiedIcon,
    ariaLabel: 'Satisfeito',
  },
  SentimentVerySatisfied: {
    icon: SentimentVerySatisfiedIcon,
    ariaLabel: 'Muito satisfeito',
  },
  Storefront: {
    icon: StorefrontIcon,
    ariaLabel: 'Loja',
  },
  TwoWheeler: {
    icon: TwoWheelerIcon,
    ariaLabel: 'Moto',
  },
  Upload: {
    icon: UploadIcon,
    ariaLabel: 'Upload',
  },
  Update: {
    icon: UpdateIcon,
    ariaLabel: 'Atualizar',
  },
  Visibility: {
    icon: VisibilityIcon,
    ariaLabel: 'Visível',
  },
  VisibilityOff: {
    icon: VisibilityOffIcon,
    ariaLabel: 'Invisível',
  },
  VolunteerActivism: {
    icon: VolunteerActivismIcon,
    ariaLabel: 'Voluntariado',
  },
  Warning: {
    icon: WarningIcon,
    ariaLabel: 'Aviso',
  },
  WatchLater: {
    icon: WatchLaterIcon,
    ariaLabel: 'Assistir mais tarde',
  },
  WhatsAppCall: {
    icon: WhatsAppCallIcon,
    ariaLabel: 'Chamada por WhatsApp',
  },
}

export const outlinedIcons = {
  CheckCircleOutline: {
    icon: CheckCircleOutlineIcon,
    ariaLabel: 'Sucesso',
  },
  DeleteOutline: {
    icon: DeleteOutlineIcon,
    ariaLabel: 'Deletar',
  },
  FileExcelOutline: {
    icon: FileExcelOutlineIcon,
    ariaLabel: 'Excel',
  },
  HelpOutline: {
    icon: HelpOutlineIcon,
    ariaLabel: 'Ajuda',
  },
  InfoOutline: {
    icon: InfoOutlineIcon,
    ariaLabel: 'Informação',
  },
  NotificationsOutline: {
    icon: NotificationsOutlineIcon,
    ariaLabel: 'Notificações',
  },
  OutlineBadge: {
    icon: OutlineBadgeIcon,
    ariaLabel: 'Erro',
  },
  OutlineCalendarMonth: {
    icon: OutlineCalendarMonthIcon,
    ariaLabel: 'Calendário',
  },
  OutlineCalendarToday: {
    icon: OutlineCalendarTodayIcon,
    ariaLabel: 'Calendário',
  },
  OutlineCall: {
    icon: OutlineCallIcon,
    ariaLabel: 'Ligar',
  },
  OutlineCameraAlt: {
    icon: OutlineCameraAltIcon,
    ariaLabel: 'Câmera',
  },
  OutlineCancel: {
    icon: OutlineCancelIcon,
    ariaLabel: 'Cancelar',
  },
  OutlineContactPhone: {
    icon: OutlineContactPhoneIcon,
    ariaLabel: 'Telefone de contato',
  },
  OutlineDelete: {
    icon: OutlineDeleteIcon,
    ariaLabel: 'Deletar',
  },
  OutlineDescription: {
    icon: OutlineDescriptionIcon,
    ariaLabel: 'Descrição',
  },
  OutlineDownload: {
    icon: OutlineDownloadIcon,
    ariaLabel: 'Baixar',
  },
  OutlineDrafts: {
    icon: OutlineDraftsIcon,
    ariaLabel: 'Rascunhos',
  },
  OutlineDraw: {
    icon: OutlineDrawIcon,
    ariaLabel: 'Desenhar',
  },
  OutlineEmail: {
    icon: OutlineEmailIcon,
    ariaLabel: 'E-mail',
  },
  OutlineFileCopy: {
    icon: OutlineFileCopyIcon,
    ariaLabel: 'Copiar',
  },
  OutlineFileDownload: {
    icon: OutlineFileDownloadIcon,
    ariaLabel: 'Baixar',
  },
  OutlineFilterAlt: {
    icon: OutlineFilterAltIcon,
    ariaLabel: 'Filtro',
  },
  OutlineHandShake: {
    icon: OutlineHandShakeIcon,
    ariaLabel: 'Aperto de mão',
  },
  OutlineHistoryEdu: {
    icon: OutlineHistoryEduIcon,
    ariaLabel: 'Histórico',
  },
  OutlineHome: {
    icon: OutlineHomeIcon,
    ariaLabel: 'Início',
  },
  OutlineHomeWork: {
    icon: OutlineHomeWorkIcon,
    ariaLabel: 'Trabalho',
  },
  OutlineHowToReg: {
    icon: OutlineHowToRegIcon,
    ariaLabel: 'Como se registrar',
  },
  OutlineModeEdit: {
    icon: OutlineModeEditIcon,
    ariaLabel: 'Editar',
  },
  OutlinePaid: {
    icon: OutlinePaidIcon,
    ariaLabel: 'Pago',
  },
  OutlinePayments: {
    icon: OutlinePaymentsIcon,
    ariaLabel: 'Pagamentos',
  },
  OutlinePeopleAlt: {
    icon: OutlinePeopleAltIcon,
    ariaLabel: 'Pessoas',
  },
  OutlinePerson: {
    icon: OutlinePersonIcon,
    ariaLabel: 'Pessoa',
  },
  OutlineReport: {
    icon: OutlineReportIcon,
    ariaLabel: 'Erro',
  },
  OutlineSaveAlt: {
    icon: OutlineSaveAltIcon,
    ariaLabel: 'Salvar',
  },
  OutlineSavings: {
    icon: OutlineSavingsIcon,
    ariaLabel: 'Economias',
  },
  OutlineSend: {
    icon: OutlineSendIcon,
    ariaLabel: 'Enviar',
  },
  OutlineShare: {
    icon: OutlineShareIcon,
    ariaLabel: 'Compartilhar',
  },
  OutlineSummarize: {
    icon: OutlineSummarizeIcon,
    ariaLabel: 'Resumo',
  },
  OutlineVisibility: {
    icon: OutlineVisibilityIcon,
    ariaLabel: 'Visível',
  },
  OutlineWhatsApp: {
    icon: OutlineWhatsAppIcon,
    ariaLabel: 'WhatsApp',
  },
  WarningOutline: {
    icon: WarningOutlineIcon,
    ariaLabel: 'Aviso',
  },
  WorkOutline: {
    icon: WorkOutlineIcon,
    ariaLabel: 'Trabalho',
  },
}

export const coloredIcons = {
  AmericanExpress: {
    icon: AmericanExpressIcon,
    ariaLabel: 'American Express',
  },
  DinersClub: {
    icon: DinersClubIcon,
    ariaLabel: 'Diners Club',
  },
  Discover: {
    icon: DiscoverIcon,
    ariaLabel: 'Discover',
  },
  Elo: {
    icon: EloIcon,
    ariaLabel: 'Elo',
  },
  Hipercard: {
    icon: HipercardIcon,
    ariaLabel: 'Hipercard',
  },
  Mastercard: {
    icon: MastercardIcon,
    ariaLabel: 'Mastercard',
  },
  Visa: {
    icon: VisaIcon,
    ariaLabel: 'Visa',
  },
  WhatsApp: {
    icon: WhatsAppIcon,
    ariaLabel: 'WhatsApp',
  },
}

export const twoToneIcons = {
  Caixa: {
    icon: CaixaIcon,
    ariaLabel: 'Banco Caixa',
  },
}

export const icons = {
  ...filledIcons,
  ...outlinedIcons,
  ...coloredIcons,
  ...twoToneIcons,
}

export {
  AccessibilityNewIcon,
  AddBusinessIcon,
  AddIcon,
  AmericanExpressIcon,
  ApprovalIcon,
  ArrowBackIcon,
  ArrowRightAltIcon,
  ArtTrackIcon,
  AssignmentIcon,
  AssignmentIndIcon,
  AssignmentTurnedInIcon,
  AttachFileIcon,
  AttachMoneyIcon,
  BarCodeIcon,
  BlockIcon,
  CachedIcon,
  CaixaIcon,
  CakeIcon,
  CancelIcon,
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  CircleIcon,
  CloseIcon,
  ContentCopyIcon,
  CreditCardIcon,
  CreditScoreIcon,
  CurrencyExchangeIcon,
  DeleteIcon,
  DeleteOutlineIcon,
  DinersClubIcon,
  DirectionsCarIcon,
  DiscoverIcon,
  DoneIcon,
  DoneAllIcon,
  DownloadIcon,
  DriveEtaIcon,
  EditIcon,
  EloIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  FacebookIcon,
  FemaleIcon,
  FilterAltIcon,
  GiftIcon,
  HandShakeIcon,
  HelpOutlineIcon,
  HipercardIcon,
  HistoryIcon,
  HomeIcon,
  HouseIcon,
  InfoIcon,
  InfoOutlineIcon,
  InstagramIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRightIcon,
  ListIcon,
  LocalAtmIcon,
  LocalShippingIcon,
  LocationCityIcon,
  MailIcon,
  MaleIcon,
  ManageSearchIcon,
  MastercardIcon,
  MoreHorizIcon,
  NotificationsIcon,
  NotificationsOutlineIcon,
  OpenInNewIcon,
  OutlineAgricultureIcon,
  OutlineBadgeIcon,
  OutlineCalendarMonthIcon,
  OutlineCalendarTodayIcon,
  OutlineCallIcon,
  OutlineCameraAltIcon,
  OutlineCancelIcon,
  OutlineContactPhoneIcon,
  OutlineDeleteIcon,
  OutlineDescriptionIcon,
  OutlineDownloadIcon,
  OutlineDraftsIcon,
  OutlineDrawIcon,
  OutlineEmailIcon,
  OutlineFileCopyIcon,
  OutlineFileDownloadIcon,
  OutlineFilterAltIcon,
  OutlineHandShakeIcon,
  OutlineHistoryEduIcon,
  OutlineHomeIcon,
  OutlineHomeWorkIcon,
  OutlineHowToRegIcon,
  OutlinePaidIcon,
  OutlinePaymentsIcon,
  OutlinePeopleAltIcon,
  OutlinePersonIcon,
  OutlineReportIcon,
  OutlineSaveAltIcon,
  OutlineSavingsIcon,
  OutlineSendIcon,
  OutlineShareIcon,
  OutlineSummarizeIcon,
  OutlineVisibilityIcon,
  OutlineWhatsAppIcon,
  PhoneAndroidIcon,
  PhoneCallbackIcon,
  PhoneForwardedIcon,
  PixIcon,
  PointOfSaleIcon,
  PriceCheckIcon,
  PriorityHighIcon,
  QueryStatsIcon,
  RGIcon,
  ReceiptLongIcon,
  RecommendIcon,
  RefreshIcon,
  RemoveIcon,
  ReportIcon,
  RotateLeftIcon,
  ScheduleIcon,
  SearchIcon,
  SelectAllIcon,
  SmsIcon,
  SentimentVeryDissatisfiedIcon,
  SentimentDissatisfiedIcon,
  SentimentNeutralIcon,
  SentimentSatisfiedIcon,
  SentimentVerySatisfiedIcon,
  StorefrontIcon,
  TwoWheelerIcon,
  UpdateIcon,
  UploadIcon,
  VisaIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  VolunteerActivismIcon,
  WarningIcon,
  WarningOutlineIcon,
  WatchLaterIcon,
  WhatsAppCallIcon,
  WhatsAppIcon,
  WorkOutlineIcon,
}

import React from 'react'
import { IconProps } from '../models'

export const DinersClubIcon = ({ size, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={size || '24'}
    height={size || '18'}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.3378 9.07826C23.3378 3.70929 18.8565 -0.00161951 13.9463 6.41413e-07H9.72087C4.75224 -0.00178153 0.662598 3.71042 0.662598 9.07826C0.662598 13.989 4.75235 18.0237 9.72087 17.9999H13.9463C18.856 18.0235 23.3378 13.9877 23.3378 9.07826Z"
      fill="#0079BE"
    />
    <path
      d="M9.74751 0.760498C5.20736 0.761902 1.52848 4.44948 1.52686 9.00059C1.52799 13.551 5.20703 17.238 9.74751 17.2396C14.2887 17.2383 17.9687 13.551 17.9692 9.00059C17.9686 4.44937 14.2888 0.762118 9.74751 0.760498Z"
      fill="white"
    />
    <path
      d="M4.55176 8.97833C4.55608 6.7544 5.94223 4.85796 7.89683 4.10437V13.8512C5.94223 13.0981 4.55602 11.2026 4.55176 8.97838V8.97833ZM11.6264 13.8536V4.10464C13.5818 4.85644 14.9701 6.75397 14.9737 8.97892C14.9701 11.2046 13.5818 13.1007 11.6264 13.8539V13.8536Z"
      fill="#0079BE"
    />
  </svg>
)

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useDownloadService } from '~/utils/useDownloadService'
import { useFiletype } from '~/utils/useFiletype'
import { useSubdomain } from '~/utils/useSubdomain'

interface ParamsInterface {
  id: string
}

const SinglePaymentDownload = () => {
  const [fileLoaded,
    setFileLoaded] = useState<boolean>(false)

  const [notFound,
    setNotFound] = useState<boolean>(false)

  const subdomain = useSubdomain()
  const { id } = useParams<ParamsInterface>()
  const { callDownloadSimplePaymentMethod } = useDownloadService()
  const { getMimetype } = useFiletype()

  useEffect(() => {
    callDownloadSimplePaymentMethod(id)
      .then((data) => {
        const file = new Blob([data], { type: getMimetype(data) })
        const fileURL = URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', `boleto_${id}.pdf`)
        document.body.appendChild(link)
        link.click()
        if (link.parentNode) link.parentNode.removeChild(link)
      })
      .catch(() => setNotFound(true))
      .finally(() => setFileLoaded(true))
  }, [
    callDownloadSimplePaymentMethod,
    id,
    getMimetype,
    subdomain,
  ])

  return (
    <div className="App">
      <header className="App-header">
        {!fileLoaded ? (
          <>
            Download em andamento. Aguarde...
          </>
        ) : (
          <>
            {notFound ? (
              <>
                Arquivo não encontrado ou indisponível no momento. Por favor, entre em contato ou tente novamente mais tarde.
              </>
            ) : (
              <>
                Arquivo baixado com sucesso.
              </>
            )}
          </>
        )}
      </header>
    </div>
  )
}

export default SinglePaymentDownload

import api from '~/config/api'
import { baseChargeIntegrations } from '~/config/basePath'

const pathUrl = `${baseChargeIntegrations}/v1/debt-collection-automation/boletos`

const getBoletoFile = (uuid: string): Promise<string> => api
  .get<string>(`${pathUrl}/${uuid}/download`, { responseType: 'arraybuffer' })
  .then(({ data }) => data)

const getBoletoSinglePaymentFile = (id: string): Promise<string> => api
  .get<string>(`${pathUrl}/${id}/singlePayment`, { responseType: 'arraybuffer' })
  .then(({ data }) => data)

export { getBoletoFile, getBoletoSinglePaymentFile }

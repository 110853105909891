import React from 'react'
import { IconProps } from '../models'
import { getUnaryColor } from '~/assets/styles'

export const OutlineWhatsAppIcon = ({ color, size, ...props }: IconProps) => (
  <svg
    width={size || '16'}
    height={size || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.7 3.27329C12.0888 2.65591 11.3608 2.1664 10.5585 1.83333C9.7561 1.50025 8.89544 1.33027 8.0267 1.33329C4.3867 1.33329 1.42003 4.29996 1.42003 7.93996C1.42003 9.10662 1.7267 10.24 2.30003 11.24L1.3667 14.6666L4.8667 13.7466C5.83337 14.2733 6.92003 14.5533 8.0267 14.5533C11.6667 14.5533 14.6334 11.5866 14.6334 7.94662C14.6334 6.17996 13.9467 4.51996 12.7 3.27329ZM8.0267 13.4333C7.04003 13.4333 6.07337 13.1666 5.2267 12.6666L5.0267 12.5466L2.9467 13.0933L3.50003 11.0666L3.3667 10.86C2.81853 9.9846 2.52746 8.97279 2.5267 7.93996C2.5267 4.91329 4.99337 2.44662 8.02003 2.44662C9.4867 2.44662 10.8667 3.01996 11.9 4.05996C12.4117 4.56927 12.8172 5.17507 13.093 5.84224C13.3688 6.50942 13.5094 7.2247 13.5067 7.94662C13.52 10.9733 11.0534 13.4333 8.0267 13.4333ZM11.04 9.32662C10.8734 9.24662 10.06 8.84662 9.91337 8.78662C9.76003 8.73329 9.65337 8.70662 9.54003 8.86662C9.4267 9.03329 9.11337 9.40662 9.02003 9.51329C8.9267 9.62662 8.8267 9.63996 8.66003 9.55329C8.49337 9.47329 7.96003 9.29329 7.33337 8.73329C6.84003 8.29329 6.51337 7.75329 6.41337 7.58662C6.32003 7.41996 6.40003 7.33329 6.4867 7.24662C6.56003 7.17329 6.65337 7.05329 6.73337 6.95996C6.81337 6.86662 6.8467 6.79329 6.90003 6.68662C6.95337 6.57329 6.9267 6.47996 6.8867 6.39996C6.8467 6.31996 6.51337 5.50662 6.38003 5.17329C6.2467 4.85329 6.1067 4.89329 6.0067 4.88662H5.6867C5.57337 4.88662 5.40003 4.92662 5.2467 5.09329C5.10003 5.25996 4.67337 5.65996 4.67337 6.47329C4.67337 7.28662 5.2667 8.07329 5.3467 8.17996C5.4267 8.29329 6.51337 9.95996 8.1667 10.6733C8.56003 10.8466 8.8667 10.9466 9.1067 11.02C9.50003 11.1466 9.86003 11.1266 10.1467 11.0866C10.4667 11.04 11.1267 10.6866 11.26 10.3C11.4 9.91329 11.4 9.58662 11.3534 9.51329C11.3067 9.43996 11.2067 9.40662 11.04 9.32662Z"
      fill={color ? getUnaryColor(color) : 'currentColor'}
    />
  </svg>
)

import React from 'react'
import { IconProps } from '../models'

export const EloIcon = ({ size, ...props }: Omit<IconProps, 'color'>) => (
  <svg
    width={size || '30'}
    height={size || '12'}
    viewBox="0 0 30 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.46728 2.93449C4.78774 2.8261 5.13175 2.76955 5.4899 2.76955C7.05447 2.76955 8.35984 3.88171 8.65673 5.35674L10.8716 4.90434C10.3627 2.39726 8.14778 0.507523 5.4899 0.507523C4.88199 0.507523 4.29763 0.606486 3.75098 0.790276L4.46728 2.93449Z"
      fill="#FFF100"
    />
    <path
      d="M1.85204 10.1164L3.35063 8.41991C2.68145 7.82613 2.26203 6.96373 2.26203 5.99766C2.26203 5.03629 2.68145 4.16918 3.35063 3.58011L1.85204 1.88831C0.716309 2.89679 0 4.3624 0 6.00237C0 7.63763 0.716309 9.1079 1.85204 10.1164Z"
      fill="#00A3DF"
    />
    <path
      d="M8.65705 6.64803C8.35545 8.12306 7.05479 9.23051 5.49022 9.23051C5.13207 9.23051 4.78805 9.17396 4.46289 9.06557L3.74658 11.2098C4.29324 11.3935 4.8823 11.4925 5.49022 11.4925C8.1481 11.4925 10.363 9.60751 10.8719 7.10043L8.65705 6.64803Z"
      fill="#EE4023"
    />
    <path
      d="M18.2517 8.16547C17.8842 8.52367 17.3894 8.7404 16.8427 8.73097C16.4657 8.72626 16.117 8.61316 15.8248 8.42466L15.0896 9.59342C15.5938 9.90911 16.1829 10.0976 16.8239 10.107C17.7522 10.1212 18.6004 9.7536 19.2179 9.1551L18.2517 8.16547ZM16.9228 3.40107C15.0755 3.3728 13.5487 4.85254 13.5203 6.69986C13.5109 7.39261 13.7088 8.04294 14.0623 8.58017L20.132 5.98351C19.7928 4.52737 18.4922 3.42935 16.9229 3.40107H16.9228ZM14.9106 6.96372C14.9012 6.88832 14.8965 6.8082 14.8965 6.72809C14.9153 5.63949 15.8107 4.76767 16.8993 4.78652C17.4931 4.79594 18.0208 5.06456 18.3743 5.48869L14.9106 6.96372ZM22.0407 1.89301V8.36335L23.1623 8.82989L22.6297 10.107L21.5176 9.64516C21.2679 9.53677 21.0982 9.37184 20.9709 9.18333C20.8484 8.99483 20.7541 8.73088 20.7541 8.3822V1.89301H22.0407ZM26.0888 4.88548C26.2868 4.8195 26.4941 4.78652 26.7156 4.78652C27.6722 4.78652 28.464 5.46512 28.6478 6.36522L30.0002 6.08718C29.6892 4.5556 28.3367 3.40574 26.7156 3.40574C26.3433 3.40574 25.9852 3.467 25.6553 3.57539L26.0888 4.88548ZM24.4912 9.26345L25.4054 8.2314C24.9955 7.86853 24.741 7.34072 24.741 6.75165C24.741 6.16258 25.0001 5.63478 25.4054 5.27662L24.4912 4.24457C23.7985 4.8572 23.3602 5.7573 23.3602 6.75637C23.3602 7.75543 23.7985 8.65081 24.4912 9.26345ZM28.6478 7.15217C28.464 8.05232 27.6675 8.73088 26.7156 8.73088C26.4988 8.73088 26.2868 8.69318 26.0888 8.62725L25.6506 9.93734C25.9852 10.0504 26.3433 10.1117 26.7156 10.1117C28.3367 10.1117 29.6892 8.96184 30.0002 7.43026L28.6478 7.15217Z"
      fill="#231F20"
    />
  </svg>
)

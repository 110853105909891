import { borderRadius, getBorderRadius } from './borderRadius'
import { breakpoints, mediaQueries } from './breakpoints'
import { colors, getUnaryColor } from './colors'
import { shadows } from './shadows'
import { spacings, getSpacing } from './spacing'
import { transitions } from './transitions'
import { typography } from './typography'
import { zIndex } from './zIndex'

export const theme = {
  borderRadius,
  breakpoints,
  colors,
  mediaQueries,
  shadows,
  spacings,
  transitions,
  typography,
  zIndex,
  getUnaryColor,
  getSpacing,
  getBorderRadius,
} as const

export type Theme = typeof theme

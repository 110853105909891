export const spacings = {
  0: '0rem',
  2: '0.2rem',
  4: '0.4rem',
  8: '0.8rem',
  12: '1.2rem',
  16: '1.6rem',
  20: '2rem',
  24: '2.4rem',
  32: '3.2rem',
  40: '4rem',
  48: '4.8rem',
  64: '6.4rem',
  128: '12.8rem',
  256: '25.6rem',
  512: '51.2rem',
} as const

export type Spacings = typeof spacings
type Spacing = keyof Spacings

export const getSpacing = (...spacingValues: [Spacing, Spacing?, Spacing?, Spacing?]) =>
  spacingValues
    .map((spacing) => spacing !== undefined && spacings[spacing])
    .filter(Boolean)
    .join(' ')

export const getUnarySpacing = (spacing?: Spacing | string) => {
  if (typeof spacing === 'number') return getSpacing(spacing)
  return spacing
}

import { useLocation } from 'react-router-dom'

import SubdomainEnum from '~/models/enums/SubdomainEnum'

const useQuery = () => new URLSearchParams(useLocation().search)

const useSubdomain = () => {
  const query = useQuery()

  const querySubdomain = query.get('subdomain') || ''
  const hostSubdomain = window.location.host.split('.')[0] || ''

  if ((<any>Object).values(SubdomainEnum).includes(querySubdomain)) {
    return querySubdomain
  }

  if ((<any>Object).values(SubdomainEnum).includes(hostSubdomain)) {
    return hostSubdomain
  }

  return SubdomainEnum.Boletos
}

export { useSubdomain }

import styled, { css } from 'styled-components'
import {
  typography, DynamicProps, dynamicProps,
} from '~/assets/styles'

export type TypographyAtomProps = {
  variant?:
    | 'display'
    | 'titleLarge'
    | 'title'
    | 'titleSmall'
    | 'textLarge'
    | 'text'
    | 'textSmall'
    | 'textXSmall'
    | 'caption'
  bold?: boolean
} & DynamicProps

export const TypographyAtom = styled.p<TypographyAtomProps>`
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  line-height: 110%;
  
  ${({ variant }) => variants[variant || 'text']}
  ${dynamicProps}
`

const variants = {
  display: css`
    font-size: ${typography.display};
  `,
  titleLarge: css`
    font-size: ${typography.titleLarge};
  `,
  title: css`
    font-size: ${typography.title};
  `,
  titleSmall: css`
    font-size: ${typography.titleSmall};
  `,
  textLarge: css`
    font-size: ${typography.textLarge};
  `,
  text: css`
    font-size: ${typography.text};
    line-height: 120%;
  `,
  textSmall: css`
    font-size: ${typography.textSmall};
    line-height: 120%;
  `,
  textXSmall: css`
    font-size: ${typography.textXSmall};
    line-height: 120%;
  `,
  caption: css`
    font-size: ${typography.caption};
    line-height: 120%;
  `,
}

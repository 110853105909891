export const borderRadius = {
  small: '0.4rem',
  large: '1.2rem',
} as const

export type BorderRadius = typeof borderRadius
export type BorderRadiusKey = keyof BorderRadius
export type BorderRadiusValue = BorderRadiusKey | (string & {})

const hasBorderRadius = (
  value: string,
): value is BorderRadiusKey => Object.keys(borderRadius).includes(value)

export const getBorderRadius = (
  ...borderRadiusValues: [
    BorderRadiusValue,
    BorderRadiusValue?,
    BorderRadiusValue?,
    BorderRadiusValue?,
  ]
) => borderRadiusValues
  .map((borderRadiusValue) => (!!borderRadiusValue && hasBorderRadius(borderRadiusValue)
    ? borderRadius[borderRadiusValue]
    : borderRadiusValue))
  .filter(Boolean)
  .join(' ')

export const getUnaryBorderRadius = (value?: BorderRadiusValue) => {
  if (!value || !Object.keys(borderRadius).includes(value)) return value
  return getBorderRadius(value as BorderRadiusKey)
}
